import { template as template_74429a46774a4fe7b80f02ed32f5cc27 } from "@ember/template-compiler";
const FKControlMenuContainer = template_74429a46774a4fe7b80f02ed32f5cc27(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
