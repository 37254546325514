import { template as template_b4f20c70183f41358c0707a9c66152f5 } from "@ember/template-compiler";
const FKLabel = template_b4f20c70183f41358c0707a9c66152f5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
