import { template as template_9b16f0467fcb47d3be0a3cfcb94f67e3 } from "@ember/template-compiler";
const SidebarSectionMessage = template_9b16f0467fcb47d3be0a3cfcb94f67e3(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
